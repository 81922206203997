.welcomewidget {

    text-align: center;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    header {
        margin: 1.5em 0 1.6em 0;
        font-size: 2em;
        font-weight: 600;
        color: #fff;
    }

    p {
        display: flex;
        align-items: center;
    }
}