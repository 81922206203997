.grid1 {

    section:nth-child(1) { grid-area: 1 / 1 / 2 / 3; }
    section:nth-child(2) { grid-area: 1 / 3 / 2 / 4; }
    section:nth-child(3) { grid-area: 1 / 4 / 3 / 5; }
    section:nth-child(4) { grid-area: 2 / 2 / 3 / 4; }
    section:nth-child(5) { grid-area: 2 / 1 / 3 / 2; }
}




