.loginpage {

    &__input {
        border-radius: 15px;
        padding: 1em;
        height: 52px;
        width: 360px;
        color: gray;
        border: 1px solid gray;
    }

    &__pwdforgot {
        color: blue;
        text-align: left;
        font-size: 1em;
    }

    &__pwdforgot:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}