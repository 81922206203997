.listwidget {

    &__list {

        li {
            padding: 0.3em;
            border-bottom: 2px solid #fff;
            
        }
    }
}