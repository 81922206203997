@media screen and (min-height: 308px) {
    .valueparagraph {
        font-size: 2.1em !important;
    }

    .updateparagraph {
        font-size: 0.7em !important;
    }

    .listparagraph {
        font-size: 0.7em !important;
    }

    .textparagraph {
        font-size: 1em !important;
    }
}

@media screen and (min-height: 800px) {
    .valueparagraph {
        font-size: 2.6em !important;
    }

    .updateparagraph {
        font-size: 0.5em !important;
    }

    .listparagraph {
        font-size: 0.1em !important;
    }

    .textparagraph {
        font-size: 1.1em !important;
    }
}

@media screen and (min-height: 1200px) {

    .valueparagraph {
        font-size: 3.1em !important;
    }

    .updateparagraph {
        font-size: 0.5em !important;
    }

    .listparagraph {
        font-size: 1.1em !important;
    }

    .textparagraph {
        font-size: 1.1em !important;
    }
}