.areachartwidget {

    &__chart  {
        width: 100%;
        height: 80%;  
        opacity: 0.7;      
    }

    &__overlay {
        position: absolute;
        top: 34%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
    }
}