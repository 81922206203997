.grid4 {
    
    .section:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
    .section:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
    .section:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
    .section:nth-child(4) { grid-area: 1 / 4 / 2 / 5; }
    .section:nth-child(5) { grid-area: 2 / 1 / 3 / 2; }
    .section:nth-child(6) { grid-area: 2 / 2 / 3 / 3; }
    .section:nth-child(7) { grid-area: 2 / 3 / 3 / 4; }
    .section:nth-child(8) { grid-area: 2 / 4 / 3 / 5; }
}