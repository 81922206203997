@media screen and (min-height: 308px) {
    .widget {
        font-size: 1.7em !important;
    }
}

@media screen and (min-height: 800px) {
    .widget {
        font-size: 1.9em !important;
    }
}

@media screen and (min-height: 1200px) {

    .widget {
        font-size: 2.1em !important;
    }
}
